.module-list-container {
    display: flex;
    justify-content: space-between; 
    align-items: stretch; 

    margin: 5rem auto 10rem auto;
    gap: 10px;

}

.module-list-container-vertical {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 90%;
    margin: 5rem auto 5rem auto;
    gap: 3rem;
}


.single-module {
    display: flex;
    flex-direction: column;
    background-color: white;
    text-align: center;

    @media (min-width: 850px) {
        width: 30%;
    }

    flex-shrink: 1;
}


ul {
    list-style-type: none; 
    margin: 0;             
    padding: 0;           
}


ul li {
    display: flex;       
    align-items: center; 
}

ul .module-checkbox-item {
    margin-bottom: 10px; 
    width: 100%;
}

ul li svg {
    margin: 0 8px; 
    width: 10%;
}


.single-module-item-list {
    border-top: solid 1px #F5F5F5;
    padding: 0.5rem;
    text-align: left;
}

li p {
    margin: 0;
    width: 100%;
}