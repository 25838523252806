@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


body {
  background-color: #F5F5F5;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

button, input, select, textarea {
  font-family: inherit;
  font-weight: inherit;
}

.App {
  max-width: 100vw; 
  box-sizing: border-box; 
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.outlet-container {
  flex: 1;
}

a {
  all: unset;
  cursor: pointer;
}

.h1-nuvotek {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 10px;
  font-weight: bold;

  @media (max-width: 1000px) {
      font-size: 2rem;
  }
} 

.box-shadow-nuvotek {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}

.round-corners-nuvotek {
  border-radius: 20px;
}

.width-70-large-res {
  @media (min-width: 1000px) {
    width: 70%;
  }
}