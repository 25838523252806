.whatsapp-chat-bubble {
    position: fixed;
    bottom: 30px;
    right: 20px;
    cursor: pointer;
    transition: transform 0.3s ease;
    z-index: 1;
  }
  
  .whatsapp-chat-bubble:hover {
    transform: scale(1.1);
  }