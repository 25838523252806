.creative-studio-section {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    
    height: auto;
    margin: 5rem auto 5rem auto;
    text-align: center;

    .text-section {
        flex: 1;
        margin-bottom: 2rem;
        text-wrap: balance;
    
        p {
            font-size: 1.1rem;
            line-height: 1.5;
            margin-bottom: 20px;
        }
    }
    
    .image-section img {
        max-width: 100%;
    }

    /*Only display the thick border to the right on PC*/
    @media (min-width: 1000px) {
        border-right: solid 50px #333333;
        margin: 5rem auto 10rem auto;
    }
}
