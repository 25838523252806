.gallery-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin: 0 auto;



    h1 {
      margin-top: 0;
      margin-bottom: 2rem;
    }

}

.gallery-container-mobile {
  margin: 0 auto;
  h1 {
    text-align: center;
    margin-bottom: 2rem;
  }
}
  
  .tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .tab {
    color: black;
    margin: 0 10px;
    padding: 10px 20px;
    border: none;
    background-color: white;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    flex: 1;
  }
  
  .tab.active {
    background-color: red;
    color: white;
  }
  
  .images {

    height: 600px;
    overflow-y: scroll; /* Allow vertical scrolling */

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .image {
    /* margin: 10px; */
    width: 200px;
    height: 150px;
    object-fit: cover;
    cursor: pointer;
    border: 2px solid transparent;
  }
  
  .image:hover {
    border: 1px solid red;
    border-radius: 5px;
  }
  
  /* Responsive styles for mobile */
  @media (max-width: 768px) {
    .tabs {
      display: grid;
      grid-template-columns: repeat(2, 1fr); /* First row with 3 tabs */
      grid-gap: 10px;
    }
    
    .images {
      flex-direction: column;
    }
  }
  
/* Override styles for slick slider arrows */
  .slick-prev:before, .slick-next:before {
    color: #ff0000a8 !important;
    font-size: 30px;
  }