.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;

    margin: 0 auto;

    box-sizing: border-box;

    @media (max-width:999px) {
        width: 90%;
    }
}

.navbar-container {
    width: 100%;
    background-color: white;
    margin-bottom: 2rem;
}

.navbar__links {
    display: flex;
    list-style-type: none;
    justify-content: space-between;
    width: 40%;

    @media (max-width:1000px) {
        display: none;
    }

    @media (min-width: 1000px) and (max-width: 1500px) {
        width: 60%;
    }
}


.navbar__links a {
    color: #333;
    font-weight: bold;

    text-decoration: none;
    background-image: linear-gradient(to right, red 0%, red 100%);
    background-position: 100% 100%; /* Start from the right */
    background-repeat: no-repeat;
    background-size: 0 2px; /* Initially no underline */
    color: black; /* Text color */
    padding-bottom: 2px; /* Spacing for the underline */
    transition: background-size 0.3s ease-in-out, background-position 0.3s ease-in-out; /* Animate background-size and position */
  
}


.navbar__links a:hover {
    background-size: 100% 2px; /* Expand the underline on hover */
}

/*Button styles*/

.navbar__cta .navbar__button {
    position: relative;  /* Allows the pseudo-element to be positioned inside */
    background-color: #FF0000; /* Initial background color */
    color: white;
    border: none;
    padding: 15px 30px;
    border-radius: 20px;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    overflow: hidden; /* Ensure pseudo-element stays within the button bounds */
    z-index: 1; /* Keep text on top */
    transition: color 0.3s ease-in-out; /* Smooth color transition */
    letter-spacing: 1px;
    text-wrap: nowrap;
}

.navbar__cta .navbar__button::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    background-color: rgba(121, 0, 0, 0.71); /* Hover background color */
    border-radius: 50%; /* Makes it round for the expanding effect */
    z-index: -1; /* Keep it behind the text */
    transition: width 0.5s ease, height 0.5s ease, top 0.5s ease, left 0.5s ease; /* Smooth grow effect */
    transform: translate(-50%, -50%); /* Center the pseudo-element */
}

.navbar__cta .navbar__button:hover::before {
    width: 300%;
    height: 300%;
    top: 50%;
    left: 50%;
}

.navbar__cta .navbar__button:hover {
    color: white; /* Keep the text color white on hover */
}
