.mobile-navbar-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .9);
    z-index: 999;
    visibility: hidden;
    transition:visibility 0.3s linear,opacity 0.3s linear;

    opacity: 0;

    background-color: rgba(245, 245, 245, 0.95);
}

.mobile-navbar-overlay-toggled {
    visibility: visible;
    opacity: 1;
}

.mobile-navbar-toggle-button {
    z-index: 1000;

    @media (min-width:1001px) {
        display: none;
    }
}

.mobile-menu-x-button {
    top: 5%;
    right: 15%;
    position: absolute; 
    font-size: 36px;
}

.mobile-navbar-container {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-40%);
    -ms-transform: translateY(-40%);
    transform: translateY(-40%);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 40%;
    width: fit-content;
    margin: 0 auto;

    text-decoration: none;
    color: #333;
    font-weight: bold;
    font-size: 2rem;

    text-align: center;

    a {
        text-decoration: none;
        background-image: linear-gradient(to right, red 0%, red 100%);
        background-position: 0 100%;
        background-repeat: no-repeat;
        background-size: 100% 2px; 
        color: black;
        padding-bottom: 2px;
    }
}
