/*PC Styles: */
.testimonials-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 5rem auto 10rem auto;
    box-sizing: border-box;

    h1 {
        margin-bottom: 2rem;
    }

    @media (min-width: 1000px) {
        padding: 0px 0px;
    }

    .testimonials-chunk {
        display: flex;
        margin: 0 auto;
        margin-bottom: 2rem;
        justify-content: space-between;
        align-items: stretch;
    }

    .testimonial-single {
        width: 30%;
        background-color: white;
        padding: 10px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

/*Mobile Styles*/
.testimonials-container-mobile {
    text-align: center;
    margin-top: 3rem;
    margin-bottom: 5rem;
}

/*Common Styles*/
.testimonial-text-par {
    font-style: italic;
    padding: 10px;
}

.testimonial-name-par {
    width: fit-content;
    margin-left: auto;
    margin-right: 1rem;
}