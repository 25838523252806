.content-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5rem auto 10rem auto;
    padding: 0 20px;
    box-sizing: border-box;

    @media (min-width: 1000px) {
        padding: 0px 0px;
    }

}

.content-section__text {
    flex: 1;
    max-width: 48%;
}

.content-section__text h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #e63946;
    margin-bottom: 20px;
}

.content-section__text p {
    font-weight: 500;
    color: #212121;
    margin-bottom: 20px;
    font-size: 1.3rem;
}

.content-section__button {
    background-color: #FF0000;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
}

.content-section__image {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    max-width: 48%;
}

.content-section__image img {
    max-width: 100%;
    height: auto;
    border-radius: 2px;
    border-right: solid 10px #333333;
    border-bottom: solid 10px #333333;
}
