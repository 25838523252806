.footer-container {
    width: 100%;
    background-color: white;
    margin-top: 2rem;
    padding: 1rem 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.3);

    .footer-content {
        margin: 0 auto;
    
        width: 70%;
    
        align-items: center;
        color: black;
        display: flex;
        justify-content: space-between;
        
        p {
            margin: 0;
            text-decoration: underline;
        }
        
        .footer_logo img {
            width: 89px;
            height: auto;
        
            @media (max-width: 800px) {
                display: none;
            }
        }
        
        @media (max-width: 1000px) {
            width: 100%;
        }
    }
}
