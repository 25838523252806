.contact-page-container {
    text-align: center;
    text-wrap: pretty;

    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        font-size: 1.2rem;
    }

    h2:not(:first-of-type):not(:last-of-type) {
        margin-top: 0;
        font-weight: 600;
    }

    h2:last-of-type {
        color: red;
        font-weight: 600;
    }

    span {
        border-bottom: 2px solid red;
    }

    span:hover {
        color: red;
    }
}

/*Styles for maps*/

.gm-ui-hover-effect {
    display: none !important; /* This hides the close button */
}


.gm-style-iw {
    width: auto !important;
    max-width: 100% !important; /* Ensure the width fits content */
    padding: 0;  /* Remove default padding */
  }

.custom-maps-info-box img {
    margin: 0 auto;
    width: 89px;
    height: auto;
}

.custom-maps-info-box {
    padding: 0;  /* Remove padding if there’s extra space */
    text-align: center;
    overflow: hidden;  /* Prevent overflow */
}