.info-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    
    height: auto;
    margin: 2rem auto;
    text-align: center;

    box-sizing: border-box;
    padding: 10px 20px;
}

@media (min-width: 1000px) {
    /*Only show bg on PC, not on Mobile, even when prop is passed. */
    .info-box-with-bg {
        background-color: #fff;
        box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.42);
    }
}

